import React, { Fragment, useEffect, useState } from 'react'
import {
  Row,
  Col,
  Input,
  InputGroup,
  Form,
  FormGroup,
  InputGroupText,
  Button,
  Modal,
  Label,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import { FormControl } from 'react-bootstrap'
import Cards from '../../../../../global/Card'
import {
  _setCurrency,
} from '../../../../../utils/GlobalFunctions'
import SelectBox from '../../../../../utils/formWrapper/SelectBox';
import { fetchBusinessCheckoutFee } from '../../../../../api/CheckoutService'
import { calculateBusinessProcessingFee } from '../../../../../utils/common'
import { Spinner } from 'reactstrap';
import FormValidationError from '../../../../../global/FormValidationError';
import GetRedirectUrl from '../common/paymentTypeRedirection'

const refundReasons = [
  { label: 'Customer complaint', value: "Customer complaint" },
  { label: 'Duplicate payment', value: "Duplicate payment" },
  { label: 'Other', value: "Other" },
];

export const RefundModal = props => {
  const [reason, setReason] = useState(null)
  const [reasonErr, setReasonErr] = useState(false)
  const [amountErr, setAmountErr] = useState(false)
  const [refundType, setRefundType] = useState('full')
  const [includeProcessingFee, setIncludeProcessingFee] = useState(false)
  const [amount, setAmount] = useState((parseFloat(props.data.amountBreakup && props.data.amountBreakup.total) - parseFloat(props.data.refund.totalAmount)).toFixed(2))
  const [refundAmount, setRefundAmount] = useState((parseFloat(props.data.amountBreakup && props.data.amountBreakup.total) - parseFloat(props.data.refund.totalAmount)).toFixed(2))
  const [notes, setNotes] = useState('')
  const [businessFee, setBusinessFee] = useState([])

  useEffect(() => {
    if (refundType === 'full') {
      setIncludeProcessingFee(true)
      getFinalRefundAmount('full', false, amount, 'refundType')
    }
  }, [refundType]);

  useEffect(() => {
    fetchBusinessFee();
  }, []);

  const fetchBusinessFee = async () => {
    let feeResponse = (await fetchBusinessCheckoutFee()).data.processingFee;
    setBusinessFee(feeResponse);
  }

  const getFinalRefundAmount = (type, includeFee, amount, name) => {
    const { amountBreakup, method } = props.data
    let finalAmount
    if (type === 'full') {
      finalAmount = parseFloat(amountBreakup.total)

      if (props.data.refund && props.data.refund.isRefunded) {
        finalAmount = finalAmount - props.data.refund.totalAmount
      }
    }

    if (type === 'base' || type === 'full') {
      if (name === 'refundType') {
        let basePaid = parseFloat(props.data.refund.baseAmount).toFixed(2)
        let remainingBaseToBePaid = parseFloat(amountBreakup.net - amountBreakup.tip - basePaid)
        let remainingBaseFeeToBePaid = amountBreakup.feePaidByCustomer ? calculateBusinessProcessingFee(businessFee, remainingBaseToBePaid, method) : amountBreakup.fee
        let baseToBePaidWithoutFee = remainingBaseToBePaid

        finalAmount = parseFloat(baseToBePaidWithoutFee).toFixed(2)

        if (includeFee) {
          finalAmount = baseToBePaidWithoutFee + remainingBaseFeeToBePaid
        } else {
          finalAmount = parseFloat(baseToBePaidWithoutFee).toFixed(2)
        }
      } else {
        let partialAmount = parseFloat(amount)
        const amountFee = calculateBusinessProcessingFee(businessFee, partialAmount, method)
        let partialAmountWithFee = amountFee + partialAmount

        finalAmount = partialAmount

        if (includeFee) {
          finalAmount = partialAmountWithFee
        } else {
          finalAmount = partialAmount
        }
      }
    }

    if (type === 'tip') {
      if (name === 'refundType') {
        let tipAmount = amountBreakup.tip
        let tipPaid = parseFloat(props.data.refund.tipAmount).toFixed(2)

        let remainingTipToBePaid = parseFloat(tipAmount - tipPaid)
        let remainingTipFeeToBePaid = amountBreakup.feePaidByCustomer ? calculateBusinessProcessingFee(businessFee, remainingTipToBePaid, method) : 0
        let tipToBePaidWithoutFee = remainingTipToBePaid

        finalAmount = tipToBePaidWithoutFee
        if (includeFee) {
          finalAmount = tipToBePaidWithoutFee + remainingTipFeeToBePaid
        } else {
          finalAmount = tipToBePaidWithoutFee
        }
      } else {
        let partialAmount = parseFloat(amount)
        const amountFee = calculateBusinessProcessingFee(businessFee, partialAmount, method)
        let partialAmountWithFee = amountFee + partialAmount

        finalAmount = partialAmount

        if (includeFee) {
          finalAmount = partialAmountWithFee
        } else {
          finalAmount = partialAmount
        }
      }
    }

    if (name === 'refundType') {
      setAmount(parseFloat(finalAmount).toFixed(2))
    }
    console.log(includeFee)
    setIncludeProcessingFee(includeFee)
    setRefundAmount(parseFloat(finalAmount).toFixed(2))
  }

  const handleChange = (name, value) => {
    if (name === 'amount') {
      getFinalRefundAmount(refundType, includeProcessingFee, value, name)
      if (!value) {
        setRefundAmount(parseFloat(0).toFixed(2))
      }
    } else if (name === 'refundType') {
      getFinalRefundAmount(value, false, amount, name)
    } else if (name === 'includeProcessingFee') {
      getFinalRefundAmount(refundType, value, amount, name)
    }
  }

  const handleSubmit = () => {
    if (!Number(amount)) {
      setAmountErr(true)
      return
    }
    if (!reason) {
      setReasonErr(true)
      return;
    }


    if (!amountErr && !reasonErr) {
      const payload = {
        paymentId: props.data.id,
        amount: parseFloat(amount),
        includeProcessingFee: refundType === 'full' ? false : includeProcessingFee,
        refundAmount: parseFloat(refundAmount),
        type: refundType,
        reason,
        notes,
      }
      props.postRefund(payload, props.data.index)
    }
  }

  return (
    <Fragment>
      <Modal isOpen={props.open} toggle={props.handleRefundModalClose} centered>
        <ModalHeader
          toggle={props.handleRefundModalClose}
          className="py-modal__header__title"
        >
          <span>Refund to {props.data.customer}</span>
        </ModalHeader>
        <ModalBody className="Refund-Modal__body">
          <Row className='mb-3'>
            <Col sm="4 text-end">
              <Label className='mt-1'>
                Refund Type
              </Label>
            </Col>
            <Col sm="7">
                <label htmlFor="refundFull" className="py-radio d-block">
                  <input
                    type="radio"
                    name="refundType"
                    value="full"
                    defaultChecked
                    id="refundFull"
                    onChange={() => {
                      handleChange('refundType', 'full')
                      setRefundType('full')
                    }}
                  />
                  <span className="py-form__element__faux"></span>
                  <span className="py-form__element__label">Refund full amount</span>
                </label>
                <label htmlFor="refundBase" className="py-radio d-block">
                  <input
                    type="radio"
                    name="refundType"
                    value="base"
                    id="refundBase"
                    onChange={() => {
                      handleChange('refundType', 'base')
                      setRefundType('base')
                    }}
                  />
                  <span className="py-form__element__faux"></span>
                  <span className="py-form__element__label">Refund custom amount</span>
                </label>
                {props.data && props.data.amountBreakup && props.data.amountBreakup.tip > 0 &&
                  <label htmlFor="refundTip" className="py-radio d-block">
                    <input
                      type="radio"
                      name="refundType"
                      value="tip"
                      id="refundTip"
                      onChange={() => {
                        handleChange('refundType', 'tip')
                        setRefundType('tip')
                      }}
                    />
                    <span className="py-form__element__faux"></span>
                    <span className="py-form__element__label">Refund tip amount</span>
                  </label>
                }
            </Col>
          </Row>
          {props.data.amountBreakup.feePaidByCustomer &&
          <Row className='mb-4'>
            <Col sm="4 text-end">
              <Label htmlFor="includeProcessingFee" className='mt-1'>
                Include Processing Fee
              </Label>
            </Col>
            <Col sm="7">
              <label htmlFor="includeProcessingFee" className="py-switch mb-1 mt-1">
                <input
                  type="checkbox"
                  id="includeProcessingFee"
                  name="includeProcessingFee"
                  autoComplete="nope"
                  className="py-toggle__checkbox"
                  checked={refundType === 'full' || includeProcessingFee}
                  disabled={refundType === 'full'}
                  onChange={(e) => {
                    handleChange('includeProcessingFee', e.target.checked)
                    setIncludeProcessingFee(e.target.checked)
                  }}
                />
                <span className="py-toggle__handle"></span>
              </label>
            </Col>
          </Row>
        }
        <Row className='mb-3'>
          <Col sm="4 text-end">
            <Label htmlFor="refundAmountLabel" className='mt-1'>
              Refund amount
            </Label>
          </Col>
          <Col sm="7">
            <InputGroup>
                <FormGroup className="box-symble-field">
                    <InputGroup>
                      <InputGroupText
                        className={`prependAddon-input-card`}
                      >
                        {props.data.currency.symbol}
                      </InputGroupText>
                      {'   '}
                      <Input
                        onChange={e => {
                          handleChange('amount', e.target.value)
                          setAmount(e.target.value)
                          e.target.value && setAmountErr(false)
                        }}
                        value={amount}
                        type="number"
                        name="amount"
                        step="any"
                        id="refundAmountLabel"
                        disabled={refundType === 'full'}
                        className={
                          props.editMountRefund
                            ? 'invoiceDisabled'
                            : ''
                        }
                      />
                    </InputGroup>
                    <FormValidationError
                      showError={amountErr}
                    />
                </FormGroup>
            </InputGroup>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm="4 text-end">
            <Label>
              Final Amount to be refunded:
            </Label>
          </Col>
          <Col sm="7">
            {props.data.currency.symbol}{refundAmount}
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm="4 text-end">
            <Label>
              Payment method
            </Label>
          </Col>
          <Col sm="7">
            <GetRedirectUrl row={props.data} className="py-text--link" />
          </Col>
        </Row>
        {props.data.method !== 'alipay' &&
        <Row className='mb-4'>
          <Col sm="4 text-end">
            <Label className='mt-1'>
              Customer paid by
            </Label>
          </Col>
          <Col sm="7">
            {props.data.method == 'bank' ?
              <div className={` py-payment-card py-payment__bank text-white`}>
                <div className="py-payment__bank-icon">
                  <i className="fal fa-university"></i>
                </div>
                {props.data.bank && <div>
                  <div className="py-payment__bank-name">{props.data.bank.name}</div>
                  {props.data.bank.number && <div className="py-payment__bank-number">ending in {props.data.bank.number}</div>}
                </div>}

              </div>
              : <Cards
                number={
                  props.data.methodToDisplay !== 'bank'
                    ? props.data.card && props.data.card.number
                    : props.data.bank && props.data.bank.number
                }
                name={
                  props.data.methodToDisplay !== 'bank'
                    ? props.data?.card?.cardHolderName
                    : props.data.bank?.name
                }
                issuer={props.data.paymentIcon}
                preview={true}
                method={props.data.method}
              />}
          </Col>
        </Row>
        }
        <Row className='mb-3'>
          <Col sm="4 text-end">
            <Label>
              Reason
            </Label>
          </Col>
          <Col sm="7">
            <SelectBox
              style={{ maxWidth: '320px' }}
              getOptionLabel={(value)=>(value["label"])}
              getOptionValue={(value)=>(value["value"])}
              isOptionSelected={(value) => {
                return value["value"] === reason
              }}
              placeholder="Please select a reason"
              id={'refund_reason'}
              aria-required={true}
              name="reason"
              onChange={(e) => {
                setReason(e.value);
                setReasonErr(false);
                handleChange('reason', e.value)
              }
              }
              options={refundReasons}
            />
            <FormValidationError
              showError={reasonErr}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="4 text-end">
            <Label className='mt-1'>
              Notes to self
            </Label>
          </Col>
          <Col sm="7">
            <FormControl
              id="refund_notes"
              as="textarea"
              rows="4"
              value={notes}
              aria-label="SSN"
              className="py-form__element__large"
              aria-describedby="basic-addon1"
              onChange={e => {
                handleChange('notes', e)
                setNotes(e.target.value)
              }}
            />
          </Col>
        </Row>
        </ModalBody>
        <ModalFooter>
        <GetRedirectUrl row={props.data} viewButton="viewButton" />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >Refund {props.loading && (<Spinner size="sm" color="default" />)}</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  )
}
