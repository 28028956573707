import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Function to safely format date
const formatDate = (date) => {
  if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
    return ""; // Prevents "Invalid time value" error
  }
  return date.toISOString().split("T")[0]; // Format: YYYY-MM-DD
};

const DatepickerWrapper = (props) => {
  const { dateFormat, className, selected, value, onChange } = props;
  const [typedDate, setTypedDate] = useState(value || null); // Store the date object
  const [inputValue, setInputValue] = useState(value ? formatDate(value) : ""); // Store input as string

  // Handle manual input without breaking
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue); // Update text field

    // If input is empty, reset the date
    if (newValue.trim() === "") {
      setTypedDate(null);
      onChange(null); // Pass null to parent
      return;
    }

    // Only parse when input is a full date (YYYY-MM-DD)
    if (/^\d{4}-\d{2}-\d{2}$/.test(newValue)) {
      const parsedDate = new Date(newValue);
      if (!isNaN(parsedDate.getTime())) {
        setTypedDate(parsedDate); // Update date state
        onChange(parsedDate); // Pass date to parent
      }
    }
  };

  return (
    <DatePicker
      {...props}
      selected={typedDate || selected || value || null}
      className={className || "form-control"}
      dateFormat={dateFormat || "yyyy-MM-dd"}
      showYearDropdown
      showMonthDropdown
      scrollableYearDropdown
      yearDropdownItemNumber={50}
      dropdownMode="select"
      disabledKeyboardNavigation={false}
      onKeyDown={(e) => e.stopPropagation()}
      onChange={(date) => {
        if (!date) {
          setTypedDate(null);
          setInputValue(""); // Clear input
          onChange(null);
        } else {
          setTypedDate(date);
          setInputValue(formatDate(date)); // Sync input
          onChange(date);
        }
      }}
      customInput={
        <input
          type="text"
          className="form-control"
          value={inputValue}
          onChange={handleInputChange} // Handle manual typing
          placeholder="YYYY-MM-DD"
        />
      }
    />
  );
};

export default DatepickerWrapper;
