export const THEME_MODE = "THEME_MODE";

export const DEMO = "DEMO";
export const ADD_CARDS = "ADD_CARDS"

export const GET_RECURRING_CARDS = "GET_RECURRING_CARDS"
export const STOP_PAYMENT_LOADING = "STOP_PAYMENT_LOADING"
export const OPEN_PAYMENT = "OPEN_PAYMENT";
export const CLOSE_PAYMENT = "CLOSE_PAYMENT";
export const OPEN_MAIL = "OPEN_MAIL";
export const CLOSE_MAIL = "CLOSE_MAIL";
export const USER_SETTINGS = "USER_SETTINGS";
export const UPDATE_PAYMENT_STATUS = "UPDATE_PAYMENT_STATUS"

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const USER_LOGOUT = "USER_LOGOUT";

export const START_USER_DATA_LOADING = "START_USER_DATA_LOADING";
export const USER_DATA = "USER_DATA";

export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILED = "SIGNUP_FAILED";

export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const RESET_ADD_PRODUCT = "RESET_ADD_PRODUCT";
export const FETCH_PRODUCT_BY_ID_LOADING = 'FETCH_PRODUCT_BY_ID_LOADING';
export const FETCH_PRODUCT_BY_ID = "FETCH_PRODUCT_BY_ID";
export const PRODUCT_FAILED = "PRODUCT_FAILED";

export const FETCH_CHECKOUTS = "FETCH_CHECKOUTS";
export const ADD_CHECKOUT = "ADD_CHECKOUT";
export const CHECKOUT_PAYMENT = "CHECKOUT_PAYMENT";
export const UPDATE_CHECKOUT = "UPDATE_CHECKOUT";
export const RESET_ADD_CHECKOUT = "RESET_ADD_CHECKOUT";
export const FETCH_CHECKOUT_BY_ID = "FETCH_CHECKOUT_BY_ID";
export const CHECKOUT_FAILED = "CHECKOUT_FAILED";

export const FETCH_CUSTOMERS = "FETCH_CUSTOMERS";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const RESET_ADD_CUSTOMER = "RESET_ADD_CUSTOMER";
export const RESET_ALL_PRODUCT_STATE = 'RESET_ALL_PRODUCT_STATE';
export const RESET_ALL_CUSTOMER_STATE = 'RESET_ALL_CUSTOMER_STATE';
export const FETCH_CUSTOMER_BY_ID = "FETCH_CUSTOMER_BY_ID";
export const CUSTOMER_FAILED = "CUSTOMER_FAILED";

export const SET_COUNTRY = "SET_COUNTRY";
export const SET_STATES = "SET_STATES";
export const RESET_STATES = "RESET_STATES";
export const SET_STATES_FOR_SHIPPING = 'SET_STATES_FOR_SHIPPING';
export const FETCH_COUNTRY_CURRENCY_LIST = "FETCH_COUNTRY_CURRENCY_LIST";
export const FETCH_BUSINESS = "FETCH_BUSINESS";
export const SELECTED_BUSINESS = "SELECTED_BUSINESS";
export const BUSINESS_FAILED = "BUSINESS_FAILED";
export const ONBOARDING_RULES = "ONBOARDING_RULES";

export const FETCH_ESTIMATES = "FETCH_ESTIMATES";
export const ADD_ESTIMATE = "ADD_ESTIMATE";
export const RESET_ADD_ESTIMATE = "RESET_ADD_ESTIMATE";
export const FETCH_ESTIMATE_BY_ID = "FETCH_ESTIMATE_BY_ID";
export const ESTIMATE_FAILED = "ESTIMATE_FAILED";

export const GET_ALL_DOCUMENTS_LOADING = 'GET_ALL_DOCUMENTS_LOADING';
export const GET_ALL_DOCUMENTS_SUCCESS = 'GET_ALL_DOCUMENTS_SUCCESS';
export const GET_ALL_DOCUMENTS_ERROR = 'GET_ALL_DOCUMENTS_ERROR';

export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const UPDATE_DATA = 'UPDATE_DATA';

export const FETCH_PAYMENT_STATE = 'FETCH_PAYMENT_STATE';
export const ADD_PAYMENT_LOADING = 'ADD_PAYMENT_LOADING';
export const ADD_BUSINESS_TYPE_ONBOARDING = 'ADD_BUSINESS_TYPE_ONBOARDING';
export const ADD_BUSINESS_DETAIL_ONBOARDING = 'ADD_BUSINESS_DETAIL_ONBOARDING';
export const ADD_YOUR_DETAIL_ONBOARDING = 'ADD_YOUR_DETAIL_ONBOARDING';
export const ADD_BANK_DETAIL_ONBOARDING = 'ADD_BANK_DETAIL_ONBOARDING';
export const ADD_CUSTOMIZED_STATEMENT_ONBOARDING = 'ADD_CUSTOMIZED_STATEMENT_ONBOARDING';
export const VERIFY_ONBOARDING = 'VERIFY_ONBOARDING';
export const VERIFY_ONBOARDING_ERROR = 'VERIFY_ONBOARDING_ERROR';

export const GET_ALL_PAYMENT_RECORDS = 'GET_ALL_PAYMENT_RECORDS';
export const GET_ALL_PAYMENT_LOADING = 'GET_ALL_PAYMENT_LOADING';
export const GET_PAYMENT_INTERMEDIATE_DATA = 'GET_PAYMENT_INTERMEDIATE_DATA';
export const GET_ALL_REFUND_RECORDS = 'GET_ALL_REFUND_RECORDS';
export const GET_ALL_REFUND_LOADING = 'GET_ALL_REFUND_LOADING';
export const POST_REFUND = 'POST_REFUND';
export const GET_PAYOUT_BALANCE_LOADING = 'GET_PAYOUT_BALANCE_LOADING';
export const GET_PAYOUT_BALANCE_RECORDS = 'GET_PAYOUT_BALANCE_RECORDS';
export const FETCH_PAYPAL_SIGN_UP_PAYLOAD = 'FETCH_PAYPAL_SIGN_UP_PAYLOAD'
export const SET_PAYPAL_SIGN_UP_PAYLOAD = 'SET_PAYPAL_SIGN_UP_PAYLOAD';
export const ERROR_PAYPAL_SIGN_UP_PROCESS = 'ERROR_PAYPAL_SIGN_UP_PROCESS'

export const GET_PAYMENT_BY_ID = 'GET_PAYMENT_BY_ID';
export const GET_REFUND_BY_ID = 'GET_REFUND_BY_ID';
export const GET_REFUND_BY_PAYMENT_ID = 'GET_REFUND_BY_PAYMENT_ID';
export const FETCH_CUSTOMER_STATEMENTS = "FETCH_CUSTOMER_STATEMENTS";

export const UPDATE_USER = 'UPDATE_USER';
export const CHANGE_PASS = 'CHANGE_PASS';
export const CHANGE_PASS_ERROR = 'CHANGE_PASS_ERROR';

export const GET_ALL_VENDORS_SUCESS = 'GET_ALL_VENDORS_SUCCESS';
export const GET_ALL_VENDORS_LOADING = 'GET_ALL_VENDORS_LOADING';
export const GET_ALL_VENDORS_ERROR = 'GET_ALL_VENDORS_ERROR';

export const ADD_VENDOR_SUCCESS = 'ADD_VENDOR_SUCCESS';
export const ADD_VENDOR_LOADING = 'ADD_VENDOR_LOADING';
export const ADD_VENDOR_ERROR = 'ADD_VENDOR_ERROR';

export const UPDATE_VENDOR_SUCCESS = 'UPDATE_VENDOR_SUCCESS';
export const UPDATE_VENDOR_LOADING = 'UPDATE_VENDOR_LOADING';
export const UPDATE_VENDOR_ERROR = 'UPDATE_VENDOR_ERROR';

export const DELETE_VENDOR_SUCCESS = 'DELETE_VENDOR_SUCCESS';
export const DELETE_VENDOR_LOADING = 'DELETE_VENDOR_LOADING';
export const DELETE_VENDOR_ERROR = 'DELETE_VENDOR_ERROR';

export const GET_BY_ID_VENDOR_SUCCESS = 'GET_BY_ID_VENDOR_SUCCESS';
export const GET_BY_ID_VENDOR_LOADING = 'GET_BY_ID_VENDOR_LOADING';
export const GET_BY_ID_VENDOR_ERROR = 'GET_BY_ID_VENDOR_ERROR';

export const GET_BY_ID_VENDOR_BANK_SUCCESS = 'GET_BY_ID_VENDOR_BANK_SUCCESS';
export const GET_BY_ID_VENDOR_BANK_LOADING = 'GET_BY_ID_VENDOR_BANK_LOADING';
export const GET_BY_ID_VENDOR_BANK_ERROR = 'GET_BY_ID_VENDOR_BANK_ERROR';

export const UPDATE_VENDOR_BANK_SUCCESS = 'UPDATE_VENDOR_BANK_SUCCESS';
export const UPDATE_VENDOR_BANK_LOADING = 'UPDATE_VENDOR_BANK_LOADING';
export const UPDATE_VENDOR_BANK_ERROR = 'UPDATE_VENDOR_BANK_ERROR';

export const SENDMAIL_SUCCESS = 'SENDMAIL_SUCCESS';
export const SENDMAIL_LOADING = 'SENDMAIL_LOADING';
export const SENDMAIL_ERROR = 'SENDMAIL_ERROR';

export const GET_ALL_ACCOUNTS_SUCCESS = 'GET_ALL_ACCOUNTS_SUCCESS';
export const GET_ALL_ACCOUNTS_LOADING = 'GET_ALL_ACCOUNTS_LOADING';
export const GET_ALL_ACCOUNTS_ERROR = 'GET_ALL_ACCOUNTS_ERROR';

export const GET_ALL_INVOICE_PAYMENTS_SUCCESS = 'GET_ALL_INVOICE_PAYMENTS_SUCCESS';
export const GET_ALL_INVOICE_PAYMENTS_LOADING = 'GET_ALL_INVOICE_PAYMENTS_LOADING';
export const GET_ALL_INVOICE_PAYMENTS_ERROR = 'GET_ALL_INVOICE_PAYMENTS_ERROR';

export const GET_ALL_CUSTOMER_CARDS_SUCCESS = 'GET_ALL_CUSTOMER_CARDS_SUCCESS';
export const GET_ALL_CUSTOMER_CARDS_LOADING = 'GET_ALL_CUSTOMER_CARDS_LOADING';
export const GET_ALL_CUSTOMER_CARDS_ERROR = 'GET_ALL_CUSTOMER_CARDS_ERROR';

export const DELETE_CUSTOMER_CARDS_SUCCESS = 'DELETE_CUSTOMER_CARDS_SUCCESS';
export const DELETE_CUSTOMER_CARDS_LOADING = 'DELETE_CUSTOMER_CARDS_LOADING';
export const DELETE_CUSTOMER_CARDS_ERROR = 'DELETE_CUSTOMER_CARDS_ERROR';

export const GET_RECEIPT_SETTINGS_SUCCESS = 'GET_RECEIPT_SETTINGS_SUCCESS';
export const SET_RECEIPT_SETTINGS_SUCCESS = 'SET_RECEIPT_SETTINGS_SUCCESS';
export const SET_RECEIPT_SETTINGS_LOADING = 'SET_RECEIPT_SETTINGS_LOADING';

export const GET_PAYMENT_SETTINGS_SUCCESS = 'GET_PAYMENT_SETTINGS_SUCCESS';
export const SET_PAYMENT_SETTINGS_SUCCESS = 'SET_PAYMENT_SETTINGS_SUCCESS';
export const SET_PAYMENT_SETTINGS_LOADING = 'SET_PAYMENT_SETTINGS_LOADING';
export const SET_PAYMENT_STATEMENT_DESCRIPTOR = 'SET_PAYMENT_STATEMENT_DESCRIPTOR';

export const GET_VERIFICATION_SUCCESS = 'GET_VERIFICATION_SUCCESS';
export const SET_VERIFICATION_SUCCESS = 'SET_VERIFICATION_SUCCESS';
export const SET_VERIFICATION_LOADING = 'SET_VERIFICATION_LOADING';

export const GET_ALL_BILLS_LOADING = 'GET_ALL_BILLS_LOADING';
export const GET_ALL_BILLS_SUCCESS = 'GET_ALL_BILLS_SUCCESS';
export const GET_ALL_BILLS_ERROR = 'GET_ALL_BILLS_ERROR';

export const GET_BILL_BY_ID_LOADING = 'GET_BILL_BY_ID_LOADING';
export const GET_BILL_BY_ID_SUCCESS = 'GET_BILL_BY_ID_SUCCESS';
export const GET_BILL_BY_ID_ERROR = 'GET_BILL_BY_ID_ERROR';

export const UPLOAD_RECEIPT_SUCCESS = 'UPLOAD_RECEIPT_SUCCESS';
export const UPLOAD_RECEIPT_LOADING = 'UPLOAD_RECEIPT_LOADING';
export const UPLOAD_RECEIPT_ERROR = 'UPLOAD_RECEIPT_ERROR';
export const UPLOAD_RECEIPT_PROGRESS = 'UPLOAD_RECEIPT_PROGRESS';

export const LIST_RECEIPTS_SUCCESS = 'LIST_RECEIPTS_SUCCESS';
export const LIST_RECEIPTS_LOADING = 'LIST_RECEIPTS_LOADING';
export const LIST_RECEIPTS_ERROR = 'LIST_RECEIPTS_ERROR';

export const UPDATE_RECEIPT_LOADING = 'UPDATE_RECEIPT_LOADING';

export const GET_ALL_RECURRING_SUCCESS = 'GET_ALL_RECURRING_SUCCESS';
export const GET_ALL_RECURRING_LOADING = 'GET_ALL_RECURRING_LOADING';
export const GET_ALL_RECURRING_ERROR = 'GET_ALL_RECURRING_ERROR';

export const GET_ALL_RECURRING_COUNT_SUCCESS = 'GET_ALL_RECURRING_COUNT_SUCCESS';
export const GET_ALL_RECURRING_COUNT_LOADING = 'GET_ALL_RECURRING_COUNT_LOADING';
export const GET_ALL_RECURRING_COUNT_ERROR = 'GET_ALL_RECURRING_COUNT_ERROR';


export const GENERATE_RESET_LINK_SUCCESS = 'GENERATE_RESET_LINK_SUCCESS';
export const GENERATE_RESET_LINK_LOADING = 'GENERATE_RESET_LINK_LOADING';
export const GENERATE_RESET_LINK_ERROR = 'GENERATE_RESET_LINK_ERROR';


export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_LOADING = 'RESET_PASSWORD_LOADING';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const VERIFY_LINK_SUCCESS = 'VERIFY_LINK_SUCCESS';
export const VERIFY_LINK_LOADING = 'VERIFY_LINK_LOADING';
export const VERIFY_LINK_ERROR = 'VERIFY_LINK_ERROR';

export const CHARGE_CARD_SUCCESS = 'CHARGE_CARD_SUCCESS';
export const CHARGE_CARD_LOADING = 'CHARGE_CARD_LOADING';
export const CHARGE_CARD_ERROR = 'CHARGE_CARD_ERROR';

export const CHECK_USER = 'CHECK_USER';

export const INVITE_USER_LOADING = 'INVITE_USER_LOADING';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_ERROR = 'INVITE_USER_ERROR';

export const UPDATE_USER_LOADING = 'UPDATE_USER_LOADING';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const DELETE_DELEGATE_LOADING = 'DELETE_DELEGATE_LOADING';
export const DELETE_DELEGATE_SUCCESS = 'DELETE_DELEGATE_SUCCESS';
export const DELETE_DELEGATE_ERROR = 'DELETE_DELEGATE_ERROR';

export const REGISTER_VERIFY_LOADING = 'REGISTER_VERIFY_LOADING';
export const REGISTER_VERIFY_SUCCESS = 'REGISTER_VERIFY_SUCCESS';
export const REGISTER_VERIFY_ERROR = 'REGISTER_VERIFY_ERROR';
export const LEGAL_DETAILS_SUCCESS = 'LEGAL_DETAILS_SUCCESS'
export const LEGAL_DETAILS_LOADING = 'LEGAL_DETAILS_LOADING'
export const LEGAL_DETAILS_ERROR = 'LEGAL_DETAILS_ERROR'

export const FETCH_ESTIMATE_LOADING = "FETCH_ESTIMATE_LOADING"
export const FETCH_ESTIMATE_SUCCESS = "FETCH_ESTIMATE_SUCCESS"
export const FETCH_ESTIMATE_ERROR = "FETCH_ESTIMATE_ERROR"

export const FETCH_INVOICE_LOADING = "FETCH_INVOICE_LOADING"
export const FETCH_INVOICE_SUCCESS = "FETCH_INVOICE_SUCCESS"
export const FETCH_INVOICE_ERROR = "FETCH_INVOICE_ERROR"

export const FETCH_STATE_LOADING = "FETCH_STATE_LOADING"
export const FETCH_STATE_SUCCESS = "FETCH_STATE_SUCCESS"
export const FETCH_STATE_ERROR = "FETCH_STATE_ERROR"

export const FETCH_IPINFO_LOADING = "FETCH_IPINFO_LOADING"
export const FETCH_IPINFO_SUCCESS = "FETCH_IPINFO_SUCCESS"
export const FETCH_IPINFO_ERROR = "FETCH_IPINFO_ERROR"

export const FETCH_MCC_LOADING = "FETCH_MCC_LOADING"
export const FETCH_MCC_SUCCESS = "FETCH_MCC_SUCCESS"
export const FETCH_MCC_ERROR = "FETCH_MCC_ERROR"

export const GET_ALL_PAYOUT_LOADING = 'GET_ALL_PAYOUT_LOADING';
export const GET_ALL_PAYOUT_SUCCESS = 'GET_ALL_PAYOUT_SUCCESS';
export const GET_ALL_PAYOUT_ERROR = 'GET_SINGLE_PAYOUT_ERROR';
export const GET_SINGLE_PAYOUT_SUCCESS = 'GET_SINGLE_PAYOUT_SUCCESS';
export const GET_SINGLE_PAYOUT_ERROR = 'GET_SINGLE_PAYOUT_ERROR';

export const GET_ACCOUNT_TYPE_SUCCESS = "GET_ACCOUNT_TYPE_SUCCESS";
export const GET_ACCOUNT_TYPE_FAILED = "GET_ACCOUNT_TYPE_FAILED";
export const GET_ALL_ACCOUNT_TYPE_LOADING = 'GET_ALL_ACCOUNT_TYPE_LOADING';

export const GET_SINGLE_ACCOUNT_TYPE_SUCCESS = "GET_SINGLE_ACCOUNT_TYPE_SUCCESS";
export const GET_SINGLE_ACCOUNT_TYPE_FAILED = "GET_SINGLE_ACCOUNT_TYPE_FAILED";
export const GET_SINGLE_ACCOUNT_TYPE_LOADING = 'GET_SINGLE_ACCOUNT_TYPE_LOADING';

export const GET_ALL_ACC_TYPE_SUCCESS = "GET_ALL_ACC_TYPE_SUCCESS";
export const GET_ALL_ACC_TYPE_FAILED = "GET_ALL_ACC_TYPE_FAILED";
export const GET_ALL_ACC_TYPE_LOADING = 'GET_ALL_ACC_TYPE_LOADING';

export const ADD_ACCOUNT_VALUE_TYPE_SUCCESS = "ADD_ACCOUNT_VALUE_TYPE_SUCCESS";
export const ADD_ACCOUNT_NAME_SUCCESS = "ADD_ACCOUNT_NAME_SUCCESS";
export const ADD_ACCOUNT_DISSCRIPTION_SUCCESS = "ADD_ACCOUNT_DISSCRIPTION_SUCCESS";

export const GET_ALL_CURRENCIES_SUCCESS = "GET_ALL_CURRENCIES_SUCCESS";
export const GET_ALL_CURRENCIES_FAILED = "GET_ALL_CURRENCIES_FAILED";
export const GET_ALL_CURRENCIES_LOADING = 'GET_ALL_CURRENCIES_LOADING';

export const SET_NEW_ACCOUNT_POPUP = 'SET_NEW_ACCOUNT_POPUP';
export const SET_EDIT_ACCOUNT_POPUP = "SET_EDIT_ACCOUNT_POPUP"
export const SET_SHOW_LOADER = 'SET_SHOW_LOADER';

export const GET_ALL_TRANSACTION_BALANCE_LOADER = 'GET_ALL_TRANSACTION_BALANCE_LOADER';
export const GET_ALL_TRANSACTION_BALANCE_SUCCESS = "GET_ALL_TRANSACTION_BALANCE_SUCCESS"
export const GET_ALL_TRANSACTION_BALANCE_FAILED = 'GET_ALL_TRANSACTION_BALANCE_FAILED';


export const GET_ALL_TRANSACTION_LOADER = 'GET_ALL_TRANSACTION_LOADER';
export const GET_ALL_TRANSACTION_SUCCESS = "GET_ALL_TRANSACTION_SUCCESS"
export const GET_ALL_TRANSACTION_FAILED = 'GET_ALL_TRANSACTION_FAILED';

export const GET_SINGLE_TRANSACTION_BALANCE_LOADER = 'GET_SINGLE_TRANSACTION_BALANCE_LOADER';
export const GET_SINGLE_TRANSACTION_BALANCE_SUCCESS = 'GET_SINGLE_TRANSACTION_BALANCE_SUCCESS'
export const GET_SINGLE_TRANSACTION_BALANCE_FAILED = 'GET_SINGLE_TRANSACTION_BALANCE_FAILED';

export const GET_ALL_ADDED_TRANSACTION_SUCCESS = 'GET_ALL_ADDED_TRANSACTION_SUCCESS';

export const SET_SHOW_TRANSACTION_LOADER = 'SET_SHOW_TRANSACTION_LOADER';

export const GET_TRANSACTION_DATA_LOADING = 'GET_TRANSACTION_DATA_LOADING';

export const  GET_TRANSACTION_ACCOUNT_FAILED = 'GET_TRANSACTION_ACCOUNT_FAILED';
export const  GET_TRANSACTION_ACCOUNT_SUCCESS = 'GET_TRANSACTION_ACCOUNT_SUCCESS';
export const  GET_TRANSACTION_ACCOUNT_LOADING = 'GET_TRANSACTION_ACCOUNT_LOADING';
export const  GET_ALL_CATEGORIES_FAILED = 'GET_ALL_CATEGORIES_FAILED';
export const  GET_ALL_CATEGORIES_SUCCESS = 'GET_ALL_CATEGORIES_SUCCESS';
export const  GET_ALL_CATEGORIES_LOADING = 'GET_ALL_CATEGORIES_LOADING';
export const  GET_ALL_TRANSACTIONS = 'GET_ALL_TRANSACTIONS';

export const GET_MORE_DATA_LOADER = 'GET_MORE_DATA_LOADER'

export const GET_SALES_TAX_SUCCESS = 'GET_SALES_TAX_SUCCESS'
export const GET_SALES_TAX_FAILED = 'GET_SALES_TAX_FAILED'
export const GET_SALES_TAX_LOADER = 'GET_SALES_TAX_LOADER'

export const GET_TIME_LINE_LOADER = 'GET_TIME_LINE_LOADER';
export const GET_TIME_LINE_SUCCESS = "GET_TIME_LINE_SUCCESS"
export const GET_TIME_LINE_FAILED = 'GET_TIME_LINE_FAILED';

export const GET_ALL_BANNER_LIST = 'GET_ALL_BANNER_LIST';
export const GET_ALL_BANNER_LIST_SUCCESS = 'GET_ALL_BANNER_LIST_SUCCESS';
export const GET_ALL_BANNER_LIST_FAILED = 'GET_ALL_BANNER_LIST_FAILED';
export const GET_ALL_BANNER_LIST_LOADER = 'GET_ALL_BANNER_LIST_LOADER';

export const TOTP_SET_UP_LOADING = 'TOTP_SET_UP_LOADING';
export const TOTP_SET_UP_DONE = 'TOTP_SET_UP_DONE';

export const GET_AVAILABLE_PROVIDER = 'GET_AVAILABLE_PROVIDER';
export const GET_AVAILABLE_PROVIDER_SUCCESS = 'GET_AVAILABLE_PROVIDER_SUCCESS';
export const GET_AVAILABLE_PROVIDER_ERROR = 'GET_AVAILABLE_PROVIDER_ERROR';

export const PAYMENT_SCREEN_LOADING = "SET_SELECTED_PROVIDER";
export const SET_SELECTED_PROVIDER_SUCCESS = "SET_SELECTED_PROVIDER_SUCCESS";
export const SET_SELECTED_PROVIDER_ERROR = "SET_SELECTED_PROVIDER_ERROR";

export const FETCH_FEATURE_FLAG_SUCCESS = "FETCH_FEATURE_FLAG_SUCCESS";

export const SUBSCRIPTION_LOADING = "SUBSCRIPTION_LOADING";
export const FETCH_ACTIVE_PLAN = "FETCH_ACTIVE_PLAN";
export const BILLING_LOADING = "BILLING_LOADING";
export const FETCH_BILLING_HISTORY = "FETCH_BILLING_HISTORY";

export const START_DEVICE_SESSION_LOADING = "START_DEVICE_SESSION_LOADING";
export const STOP_DEVICE_SESSION_LOADING = "STOP_DEVICE_SESSION_LOADING";
export const SET_SESSION_ERROR_STATE = "SET_SESSION_ERROR_STATE";
export const SET_MY_DEVICE_SESSION = "SET_MY_DEVICE_SESSION";

export const START_FUNDING_ORDERS_LOADING = "START_FUNDING_ORDERS_LOADING";
export const STOP_FUNDING_ORDERS_LOADING = "STOP_FUNDING_ORDERS_LOADING";
export const SET_FUNDING_ORDERS_ERROR_STATE = "SET_FUNDING_ORDERS_ERROR_STATE";
export const SET_FUNDING_ORDERS = "SET_FUNDING_ORDERS";

export const SUBMIT_PAYBYBANK_ONBOARDING_LOADING = 'SUBMIT_PAYBYBANK_ONBOARDING_LOADING';
export const SUBMIT_PAYBYBANK_ONBOARDING_SUCCESS = 'SUBMIT_PAYBYBANK_ONBOARDING_SUCCESS';
export const SUBMIT_PAYBYBANK_ONBOARDING_ERROR = 'SUBMIT_PAYBYBANK_ONBOARDING_ERROR';

export const SUBMIT_BNPL_ONBOARDING_LOADING = 'SUBMIT_BNPL_ONBOARDING_LOADING';
export const SUBMIT_BNPL_ONBOARDING_SUCCESS = 'SUBMIT_BNPL_ONBOARDING_SUCCESS';
export const SUBMIT_BNPL_ONBOARDING_ERROR = 'SUBMIT_BNPL_ONBOARDING_ERROR';
